import React, {useContext} from 'react';
import styled from 'styled-components';
import NavigationLink from '../atoms/NavigationLink'
import { PageContext } from '../../context/PageContext';
import {NavLink} from 'react-router-dom';
import {routes} from '../../routes';
import uuid from 'react-uuid';

const Wrapper = styled.nav`
    display: flex;
    flex-direction: column;
`;

const FooterNavigation = () => {
    const {pages, isLoadPages} = useContext(PageContext);
    return isLoadPages ? (
        <Wrapper>
            {
                pages.map(({title, friendlyUrl, position}) => (
                        position === 1 ? (
                            <NavigationLink footer="true" as={NavLink} to={routes.home} key={uuid()}>{title}</NavigationLink>
                        ) : friendlyUrl === 'produkty' ? (
                            <NavigationLink footer="true" as={NavLink} to={routes.products} key={uuid()}>{title}</NavigationLink>
                        ) : (
                            <NavigationLink footer="true" as={NavLink} to={'/info/' + friendlyUrl} key={uuid()}>{title}</NavigationLink>
                        )
                ))
            }
        </Wrapper>
    ) : (
        <p>ala</p>
    )
}

export default FooterNavigation;