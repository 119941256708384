import styled, {css} from 'styled-components';
import navIcon from '../../assets/icons/arrow-nav.svg';
const NavigationLink = styled.a`
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    ${({dropdown}) =>
        dropdown && css`
        position: relative;
        padding: 10px 30px;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            width: 24px;
            height: 24px;
            background: url(${navIcon});
            background-repeat: no-repeat;
            background-position: right center;
            transition-duration: 0.4s;
            transform: ${({activeItem}) => (activeItem ? 'rotate(180deg)' : 'rotate(0)')}
        }
        `
    }
    ${({footer}) => 
        footer && css`
        width: 100%;
        color: ${({theme}) => theme.color_2};
        font-size: 14px;
        padding: 0;
        cursor: pointer;
        text-decoration: none;
        transition-duration: 0.3s;
        &:hover {
            transform: translateX(10px);
        }
        `
    }
    @media (max-width: 980px) {
        width: 100%;

    }
`;

export default NavigationLink;