import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { api } from '../api';

export const PageContext = createContext();

const PageContextProvider = (props) => {
    const [pages, setPage] = useState({});
    const [isLoadPages, setIsLoadPages] = useState(false);

    const [status, setStatus] = useState({});
    const [isLoadStatus, setIsLoadStatus] = useState(false);

    const [news, setNews] = useState({});
    const [isLoadNews, setIsLoadNews] = useState(false);

    const [isLoadProducts, setLoadProducts] = useState(false);
    const [products, setProducts] = useState({});
    const [productsToSet, setProductsToSort] = useState({});
    const [filterProducts, setFilterPorducts] = useState({});
    const [currentPageProducts, setCurrentPageProducts] = useState(1);
    const [postsPerPageProducts, setPostsPerPageProducts] = useState(60);
	const [theLatestProducts, setTheLatestProducts] = useState([]);

    const [searchValue, setSearchValue] = useState("");
    const [searchValueType, setSearchValueType] = useState("all");

    const [ranking, setRanking] = useState({});
    const [isLoadRanking, setIsLoadRanking] = useState(false);

    const [category, setCategory] = useState({});
    const [isLoadCategory, setIsLoadCategory] = useState(false);

    useEffect(() => {
        const loadPages = async () => {
            axios.get(`${api.apiUrl}/cms/page`, {
                headers: {
                    appToken: api.appToken,
                }
            })
            .then(({data}) => {
                    setPage([...data.data]);
                    setIsLoadPages(true);
            })
            .catch(err => console.log(err));
        }
        const loadStatus = async () => {
            axios.get(`${api.apiUrl}/stock-product/state-news`, {
                headers: { appToken: api.appToken }
            })
            .then(({data}) => {
                setStatus([...data.data]);
                setIsLoadStatus(true);
            })
            .catch(err => console.log(err));
        }
        const loadProducts = async () => {
            axios.get(`${api.apiUrl}/product`, {
                headers: { appToken: api.appToken }
            })
            .then(({data}) => {
                setProducts([...data.data]);
                setFilterPorducts([...data.data].sort((a, b) => {
                    return b.quantity - a.quantity
                }));
                setProductsToSort([...data.data]);
                setLoadProducts(true);
            })
            .catch(err => console.log(err));
        }
        const loadNews = async () => {
                axios.get(`${api.apiUrl}/cms/news/`, {
                   headers: {
                       appToken: api.appToken,
               }})
               .then(({data}) => {
                   setNews([...data.data]);
                   setIsLoadNews(true);
               })
               .catch(err => console.log(err));
        }
        loadPages();
        loadStatus();
        loadNews();
        loadProducts();
		if (products.length) {
            const latestProducts = getLatestProducts(products)
			setTheLatestProducts(latestProducts.length ? latestProducts.slice(0, 12) : [])
		}
    }, [isLoadProducts]);

    const getLatestProducts = (products) => {
        products.sort((a, b) => {
            if (a.dateAdded.replace(/-/g, "") > b.dateAdded.replace(/-/g, "")) {
                return -1
            }
            if (a.dateAdded.replace(/-/g, "") < b.dateAdded.replace(/-/g, "")) {
                return 1;
            }
            return 0;
        })
        return products
    }

    const filterProductsByType = (value) => {
        if (value === "non_available") {
            setFilterPorducts([...products.filter(item => item.state === "non_available")]);
        }
        if (value === "normal") {
            setFilterPorducts([...products.filter(item => item.state !== "non_available")]);
        }
        if (value === 'latest') {
            setFilterPorducts([...products.sort((a, b) => {
                if (a.dateAdded.replace(/-/g, "") > b.dateAdded.replace(/-/g, "")) {
                    return -1
                }
                if (a.dateAdded.replace(/-/g, "") < b.dateAdded.replace(/-/g, "")) {
                    return 1;
                }
                return 0;
            })]);
        }
        if (value === "default") {
            setFilterPorducts([...products.sort((a, b) =>
                b.quantity - a.quantity
            )])
        }
        if (value === "default_2") {
            setFilterPorducts([...products.sort((a, b) => {
                if (a.dateAdded.replace(/-/g, "") < b.dateAdded.replace(/-/g, "")) {
                    return -1
                }
                if (a.dateAdded.replace(/-/g, "") > b.dateAdded.replace(/-/g, "")) {
                    return 1;
                }
                return 0;
            })]);
        }
        if (value === "a_z") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                a.name.toLowerCase().replace(/ /g, "").localeCompare(b.name.toLowerCase().replace(/ /g, ""))
            )])
        }
        if (value === "z_a") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                b.name.toLowerCase().replace(/ /g, "").localeCompare(a.name.toLowerCase().replace(/ /g, ""))
            )])
        }
        if (value === "price") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                a.priceGross - b.priceGross
            )])
        }
        if (value === "price_2") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                b.priceGross - a.priceGross
            )])
        }
        if (value === "profit") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                a.profit - b.profit
            )])
        }
        if (value === "profit_2") {
            setFilterPorducts([...productsToSet.sort((a, b) =>
                b.profit - a.profit
            )])
        }
        setSearchValue("");
        setCurrentPageProducts(1);
    }
    const filterPorductsByName = () => {
        setFilterPorducts([...products.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.sku.includes(searchValue)
        )
        ]);
        setCurrentPageProducts(1);
    }
    const resetProductsFilters = () => {
        setSearchValue("");
        setFilterPorducts([...products]);
        setSearchValueType("");
        filterProductsByType("default");
        setCurrentPageProducts(1);
    }
    return (
        <PageContext.Provider value={{
            pages, isLoadPages,
            products,isLoadProducts,
            status, isLoadStatus,
            news, isLoadNews,
            currentPageProducts,
            postsPerPageProducts,
            setCurrentPageProducts,
            filterProducts, setFilterPorducts,
            filterProductsByType,
            filterPorductsByName,
            searchValue,
            setSearchValue,
            searchValueType,
            setSearchValueType,
            resetProductsFilters,
            ranking, setRanking,
            isLoadRanking, setIsLoadRanking,
            category, setCategory,
            isLoadCategory, setIsLoadCategory,
			theLatestProducts

        }}>
            {props.children}
        </PageContext.Provider>
    )
}
export default PageContextProvider;
