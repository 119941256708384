import styled, {css} from 'styled-components';
import searchIcon from '../../assets/icons/search.svg';

const Input = styled.input`
    width: 100%;
    border: 1px solid ${({theme}) => theme.color_2};
    padding: 10px 35px;
    border-radius: 50px;
    font-size: ${({size}) => size || '16px'};
    ${({search}) =>
        search && css`
            background-image: url(${searchIcon});
            background-repeat: no-repeat;
            background-position: 10px center;
        `
    }
`;

export default Input;