import React, {useContext} from 'react';
import styled from 'styled-components';
import SpecialTemplates from '../templates/SpecialTemplates';
import uuid from 'react-uuid';

import Loading from '../components/molecules/Loading';
import State from '../components/molecules/State';
import { PageContext } from '../context/PageContext';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;
const StatusPage = () => {
    const {status, isLoadStatus} = useContext(PageContext);
    return isLoadStatus ? (
        <SpecialTemplates title="Statusy" type="status" color="true">
            <Wrapper>
                {
                    status.map(({id, type, product, dateAdded })=> (
                        <State
                            key={uuid()}
                            id={id}
                            idProduct={product.id}
                            name={product.name}
                            status={type}
                            date={dateAdded}
                        />
                    ))
                }
            </Wrapper>
        </SpecialTemplates>
    ) : (
        <Loading></Loading>
    )
};
export default StatusPage;
