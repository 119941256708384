import React from 'react';
import styled from 'styled-components';
import SearchPanel from '../components/organisms/SearchPanel';
import Header from '../components/organisms/Header';

const Wrapper = styled.div`
    ${({theme}) => theme.container};
    padding: 50px 0;
    justify-content: space-between;
    @media(max-width: ${({theme}) => theme.tablet}) {
        padding: 25px 10px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {

    }
`;

const Container = styled.div`
    background: #f9f9f9;
`;

const ProductsTemplates = ({children, content, searchredirect}) => (
    <Container>
    <Header title="Produkty" image={false} content={content}/>
    <SearchPanel searchredirect={searchredirect}/>
    <Wrapper>
        {children}
    </Wrapper>
    </Container>

);

export default ProductsTemplates;