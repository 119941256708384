import React from 'react';
import styled from 'styled-components';
import PaginationItem from '../atoms/PaginationItem';
import uuid from 'react-uuid';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`;
const Pagination = ({productsPerPage, totalProducts, paginate, activeProducts}) => {
    const pageNumber = [];

    for(let i = 1; i <=Math.ceil(totalProducts / productsPerPage); i++) {
        pageNumber.push(i);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <Wrapper>
            {
                pageNumber.map(number => (
                    (number === activeProducts) ? (
                        <PaginationItem active onClick={() => paginate(number)} key={uuid()}>{number}</PaginationItem>
                     ) : (
                        <PaginationItem onClick={() => {paginate(number); scrollToTop();}} key={uuid()}>{number}</PaginationItem>
                     )
                ))
            }
        </Wrapper>
    )
}
export default Pagination