import styled, {css} from 'styled-components';

const Button = styled.a`
    display: inline-block;
    padding: 15px 25px;
    color: white;
    border-radius: 100px;
    background: ${({theme, primary}) => (primary ? theme.color_1 : theme.color_11)};
    font-weight: 700;
    margin: ${({margin}) => margin || '0'};
    text-decoration: none;
    transition-duration: 0.3s;
    cursor: pointer;
    ${({big}) =>
        big && css`
            font-size: 20px;
            padding: 20px 30px;
    `}
    ${({small}) =>
        small && css`
            font-size: 14px;
            padding: 10px 20px;
    `}
    &:hover {
        background: ${({theme, primary}) => (primary ? theme.color_1_hover : theme.color_11_hover)};
    }
`;

export default Button;