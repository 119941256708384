import styled from 'styled-components';

const PaginationItem = styled.span`
    background: ${({theme, active}) => (active ? theme.color_1 : theme.color_2)};
    color: ${({active}) => (active ? 'white' : 'black')};
    margin: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        background: ${({theme}) => theme.color_1};
        color: white;
    }
`;

export default PaginationItem;