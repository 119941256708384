import React from 'react';
import styled from 'styled-components';
import MainNavigation from '../components/molecules/MainNavigation';

const Container = styled.div`
    background: ${({theme}) => theme.color_3};
    z-index: -1;
`;
const Wrapper = styled.div`
    ${({theme}) => theme.container};
    padding: 10px;
`;
const PageNotFoundTemplates = ({children}) => (
    <Container>
        <MainNavigation/>
        <Wrapper>
            {children}
        </Wrapper>
    </Container>
);

export default PageNotFoundTemplates;