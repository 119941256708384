import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../atoms/Paragraph';
import Strong from '../atoms/Strong';
import uuid from 'react-uuid';

const Wrapper = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    border-radius: 5px;
    background: white;
`;
const Information = styled.div`
    padding: 10px;
`;
const Number = styled.span`
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
`;
const Ranking = ({userID, created, realizated, position}) => (
        <Wrapper key={uuid()}>
            <Number>{position}.</Number>
            <Information>
                <Paragraph bold>ID użytkownika: {userID}</Paragraph>
                <Paragraph size={'14px'}>Zrealizowane: <Strong>{realizated}</Strong></Paragraph>
                <Paragraph size={'14px'}>Utworzone: <Strong>{created}</Strong></Paragraph>
            </Information>
        </Wrapper>
);

Ranking.propTypes = {
        userID: PropTypes.number,
        crated: PropTypes.number,
        realizated: PropTypes.number,
        prize: PropTypes.number,
};
Ranking.defaultProps = {
    userID: 0,
    crated: 0,
    realizated: 0,
    prize: 0,
}

export default Ranking;