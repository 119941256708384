import styled from 'styled-components';

const Paragraph = styled.p`
    color: ${({white, theme}) => (white ? theme.color_2 : theme.color_4)};
    font-size: ${({size}) => size || '16px' };
    margin: ${({margin}) => margin || '0'};
    font-weight: ${({bold}) => (bold ? '700' : '400')};
    @media(max-width: ${({theme}) => theme.phone}) {
        font-size: ${({smallmobile}) => (smallmobile ? '14px' : '16px' )}
    }
`;

export default Paragraph;