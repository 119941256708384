import React from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import MainNavigation from '../molecules/MainNavigation';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';
import imgMain from '../../assets/undraw_researching_22gp.svg';
import { routes } from '../../routes';

const Wrapper = styled.header`
    background: ${({theme}) => theme.color_3};
    width: 100%;
    padding-top: 80px;
`;
const Description = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    width: 60%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 70%
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        width: 100%;
        align-items: center;
        text-align: center;
        padding: 0 25px;
    }
`;
const Content = styled.div`
    ${({theme}) => theme.container};
    display: flex;
    height: 600px;
    position: relative;
    padding: 0 10px;
    @media(max-width: ${({theme}) => theme.tablet}) {
    }

`;

const Image = styled.img`
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 50%;
    z-index: 2;
    @media(max-width: ${({theme}) => theme.tablet}) {
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        bottom: 10px;
        width: 60%;
    }
`;
const StyledHeading = styled(Heading)`

`;

const MainHeader = () => {
    return (
        <Wrapper>
            <MainNavigation/>
            <Content>
                <Description>
                    <StyledHeading as={'h1'} white big>Zostań Sprzedawcą już Dziś</StyledHeading>
                    <Paragraph primary white margin={'10px 0 20px'} size={'20px'}>Wystawiaj nasze produkty na facebook market place i za każdy sprzedany przedmiot otrzymuj nagrodę.</Paragraph>
                    <Paragraph>
                        <Button primary margin={'0 0 10px 0'}>Zadzwoń: 18 44 89 218</Button>
                        <Button href={routes.salesPanel} margin={'0 0 0 10px'} target="_blank">Dołącz do nas</Button>
                    </Paragraph>
                </Description>
                <Image src={imgMain}/>
            </Content>
        </Wrapper>
    )
};

export default MainHeader;