import React from 'react';
import styled from 'styled-components';
import Header from '../components/organisms/Header';
import Banner from '../components/organisms/Banner';

const Container = styled.div`
    background: ${({theme, color}) => (color ? 'white' : theme.color_2)};
    z-index: -1;
`;
const Wrapper = styled.div`
    ${({theme}) => theme.container};
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media(max-width: ${({theme}) => theme.tablet}) {
        padding: 25px 10px;
    }
`;
const Content = styled.div`
    padding: 0 10px;
    width: 100%;
`;
const DetailContent = styled.div`
    padding: 0 10px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
`;
const SpecialTemplates = ({children, title, content, type, color}) => (
    <Container color={color}>
    <Header title={title} content={content}/>
    <Wrapper>
        {
            type === 'detail' ? (
                <DetailContent>
                    {children}
                </DetailContent>
            ) : (
                <Content>
                    {children}
                </Content>
            )
        }
    </Wrapper>
    <Banner/>
</Container>
);

export default SpecialTemplates;