import React, {useContext} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { PageContext } from '../../context/PageContext';
import uuid from 'react-uuid';
import {routes} from '../../routes'

const Wrapper = styled.ul`
    background: white;
    position: absolute;
    right: -69%;
    top: 40px;
    padding: 10px 0;
    width: 240px;
    border-radius: 10px;
    z-index: 9999999;
    box-shadow: ${({theme}) => theme.shadow};
    display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
    &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background: white;
        top: -6px;
        left: calc(50%);
        transform: rotate(45deg);
    }
    @media(max-width: ${({theme}) => theme.tablet}) {
        box-shadow: none;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        background: none;
        &:before {
            display: none;
        }
    }
    @media(max-width: ${({theme}) => theme.phone}) {
    }
`;
const NavigationItem = styled.li`
    list-style: none;
    text-align: center;
`
const NavigationLink = styled.a`
    display: inline-block;
    width: 100%;
    padding: 8px 0;
    text-decoration: none;
    color: black;
    background: white;
    font-weight: 700;
    font-size: 13px;
    border-radius: 10px;
    transition-duration: 0.3s;
    @media(max-width: ${({theme}) => theme.tablet}) {
        background: none;
        color: white;
        font-weight: normal;
        border-radius: 0;
        border-top: 1px solid ${({theme}) => theme.color_3};
    }
    &:hover {
        transform: scale(1.05);
        box-shadow: ${({theme}) => theme.shadow};
    }
`;
const InnerNavigation = ({isVisible, isVisibleMenu, closeMobileMenu, isActiveMobileMenu}) => {
    const { pages, isLoadPages, resetProductsFilters} = useContext(PageContext);
    return isLoadPages ? (
        <Wrapper isVisible={isVisible}>
            {
                pages.map(({title, friendlyUrl, position}) => (
                    <NavigationItem key={uuid()}>
                        {
                            position === 1 ? (
                                <NavigationLink
                                    as={NavLink}
                                    to={routes.home}
                                    onClick={() => {
                                        isVisibleMenu();
                                        if (isActiveMobileMenu) {
                                            closeMobileMenu()
                                        }}
                                    }
                                >
                                    {title}
                                </NavigationLink>
                            ) : friendlyUrl === 'produkty' ? (
                                <NavigationLink
                                    as={NavLink}
                                    to={routes.products}
                                    onClick={() => {
                                        resetProductsFilters();
                                        isVisibleMenu();
                                        if (isActiveMobileMenu) {
                                            closeMobileMenu()
                                        }
                                }}>
                                    {title}
                                </NavigationLink>
                            ) : (
                                <NavigationLink
                                    as={NavLink}
                                    to={'/info/' + friendlyUrl}
                                    onClick={() => {
                                        isVisibleMenu();
                                        if (isActiveMobileMenu) {
                                            closeMobileMenu()
                                        }
                                }}>
                                    {title}
                                </NavigationLink>
                            )
                        }
                    </NavigationItem>
                ))
            }
        </Wrapper>
        ) : (
        <>
        </>
    )

}

export default InnerNavigation;
