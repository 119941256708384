import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../atoms/Paragraph';
import Date from '../atoms/Date';
import availableIcon from '../../assets/icons/available.svg';
import lackIcon from '../../assets/icons/lack.svg';
import endingIcon from '../../assets/icons/ending.svg';
import uuid from 'react-uuid';
import {NavLink} from "react-router-dom";
import {routes} from "../../routes";

const Wrapper = styled.div`
    background: white;
    overflow: hidden;
    /* box-shadow: ${({theme}) => theme.shadow}; */
    text-align: left;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 10px 0 0 0;
`;
const StyledParagraph = styled(Paragraph)`
    font-weight: 700;
    font-size: 16px;
    /* background: ${({theme, activeColor}) => (activeColor ? theme[activeColor] : theme.color_3)}; */
    margin: 0;
    color: ${({theme, activeColor}) => (activeColor ? theme[activeColor] : theme.color_3)};
    padding: 5px 0;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: calc(50% - 12px);
        right: 0;
        width: 24px;
        height: 24px;
        background-image: url(${({icon}) => icon});
        background-color: ${({theme, activeColor}) => (activeColor ? theme[activeColor] : theme.color_3)};
        border-radius: 50px;
    }
`;
const Wrap = styled.div`
    padding: 0 10px 10px 0;
    border-bottom: 2px solid ${({theme}) => theme.color_2};
`;
const State = ({id, idProduct, status, name, date}) => (
    <Wrapper key={uuid()} as={NavLink} to={routes.products + '/' + idProduct}>
        {status === 'non_available' && (
            <StyledParagraph activeColor={'danger_color'} icon={lackIcon}>Niedostępny</StyledParagraph>
        )}
        {status === 'low_state' && (
            <StyledParagraph activeColor={'color_4'} icon={endingIcon}>Na wyczerpaniu</StyledParagraph>
        )}
        {status === 'delivery' && (
            <StyledParagraph activeColor={'color_11'} icon={availableIcon}>Dostawa produktu</StyledParagraph>
        )}
        <Wrap>
            <Date>{date}</Date>
            <Paragraph size="14px" margin={'5px 0 0'} >{name}</Paragraph>
        </Wrap>
    </Wrapper>
);

State.propTypes = {
    idProduct: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.number,
};
State.defaultProps = {
    status: 'brak',
    content: 'brak',
    date: 'brak',
}
export default State;
