import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../atoms/Paragraph';
import Strong from '../atoms/Strong';
import uuid from 'react-uuid';
import arrowIcon from '../../assets/icons/arrow-nav-black.svg';

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 5px;
    flex-direction: column;
    border-radius: 0;
    border-bottom: 2px solid ${({theme}) => theme.color_2};
    padding: 10px;
    cursor: pointer;
    /* background: ${({theme}) => theme.color_2}; */
`;
const Information = styled.div`
    display: ${({isActive}) => (isActive ? 'block' : 'none')};
    padding: 5px 0 0 28px;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    transition-duration: 0.4s;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background: url(${arrowIcon});
        background-repeat: no-repeat;
        background-position: 50%;
        transition-duration: 0.4s;
        transition-property: transform;
        transform: ${({isActive}) => (isActive ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }

`;
const Number = styled.span`
    width: 28px;
    font-weight: bold;
    font-size: 16px;
    padding-right: 10px;
`;

const Rank = ({userID, created, realizated, prize, position}) => {
    const [isActiveRanking, setIsActiveRanking] = useState(false);
    const setActiveRanking = () => {
        setIsActiveRanking(!isActiveRanking)
    }
    return (
        <Wrapper key={uuid()} onClick={setActiveRanking}>
            <InnerWrapper isActive={isActiveRanking}>
                <Number>{position}.</Number>
                <Paragraph>ID użytkownika: <Strong>{userID}</Strong></Paragraph>
            </InnerWrapper>
            <Information isActive={isActiveRanking}>
                <Paragraph size={'14px'}>Zrealizowane zamówienia: <Strong>{realizated}</Strong></Paragraph>
                <Paragraph size={'14px'}>Utworzone zamówienia: <Strong>{created}</Strong></Paragraph>
                <Paragraph size={'14px'}>Przewidywana nagroda: <Strong>{prize} zł</Strong></Paragraph>
            </Information>
        </Wrapper>
    )
};

Rank.propTypes = {
        userID: PropTypes.number,
        crated: PropTypes.number,
        realizated: PropTypes.number,
        prize: PropTypes.number,
};
Rank.defaultProps = {
    userID: 0,
    crated: 0,
    realizated: 0,
    prize: 0,
}

export default Rank;