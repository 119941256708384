import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {PageContext} from '../context/PageContext';
import ProductsTemplates from '../templates/ProductsTemplates';
import Loading from '../components/molecules/Loading';
import uuid from 'react-uuid';
import Product from '../components/molecules/Product';
import Pagination from '../components/molecules/Pagination';

const Content = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    @media(max-width: ${({theme}) => theme.tablet}) {
       grid-template-columns: repeat(2, 1fr);
       grid-gap: 20px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;

const CategoryPorductsPage = (props) => {
    const {isLoadProducts, currentPageProducts, postsPerPageProducts, setCurrentPageProducts, filterProducts, setFilterPorducts, products, category, isLoadCategory} = useContext(PageContext);
    const [categoryName, setCategoryName] = useState("");
    const [isLoadCategoriesProducts, setIsLoadCategoriesProducts] = useState(false);
    const indexOfLastPost = currentPageProducts * postsPerPageProducts;
    const indexOfFirsPost = indexOfLastPost - postsPerPageProducts;


    let currentPosts;
    if (filterProducts.length) {
        currentPosts = filterProducts.slice(indexOfFirsPost, indexOfLastPost);
    }
    const paginate = (pageNumber) => setCurrentPageProducts(pageNumber);

    useEffect(() => {
        if (isLoadProducts === true && isLoadCategory === true) {
            setFilterList(props.match.params.id);
            setCategory(props.match.params.id);
        }
    }, [isLoadProducts,isLoadCategory, props]);

    const setFilterList = (id) => {
        setFilterPorducts([...products.filter((item) =>
            {
                let items;
                items = item.categories.join();
                return items.includes(id)
            }
        )]);
        setCurrentPageProducts(1);
        setIsLoadCategoriesProducts(true);
    }
    const setCategory = (id) => {
        const activeCategory = category.filter(item => item.id == id);
        if (activeCategory.length) {
            setCategoryName(activeCategory[0].name);
        }
    }
    return isLoadCategoriesProducts ? (
        <ProductsTemplates content={categoryName} searchredirect="true">
            <Content>
            {
              currentPosts ? (
                    <>
                        {
                            currentPosts.map(({id, name, images, profit, priceGross, sku, state}) => (
                                <Product
                                    id={id}
                                    key={uuid()}
                                    name={name}
                                    image={images[0]}
                                    profit={profit}
                                    price={priceGross}
                                    productsKey={sku}
                                    state={state}
                                />
                            ))
                        }
                        </> ) : (
                            <p>Brak produktów</p>
                        )
            }
            </Content>
            {
                filterProducts.length > postsPerPageProducts && (
                    <Pagination productsPerPage={postsPerPageProducts} totalProducts={filterProducts.length} paginate={paginate} activeProducts={currentPageProducts}/>
                )
            }
        </ProductsTemplates>
    ) : (
        <ProductsTemplates>
           <Loading/>
        </ProductsTemplates>
    )
}
export default CategoryPorductsPage;

    // const indexOfLastPost = currentPageProducts * postsPerPageProducts;
    // const indexOfFirsPost = indexOfLastPost - postsPerPageProducts;
    // const [products, setProducts] = useState({});
    // const [isLoadProducts, setIsLoadProducts] = useState(false);

    // useEffect(() => {
    //         axios.get(`${api.apiUrl}/category/${props.match.params.id}/products`, {
    //             headers: { appToken: api.appToken }
    //         })
    //         .then(({data}) => {
    //             setProducts([...data.data]);
    //             setIsLoadProducts(true);
    //             console.log('aaa');
    //         })
    //         .catch(err => console.log(err));
    //     console.log(products);
    // },[props]);


    // // let currentPosts;
    // // if (filterProducts.length) {
    // //     currentPosts = filterProducts.slice(indexOfFirsPost, indexOfLastPost);
    // // }
    // // const paginate = (pageNumber) => setCurrentPageProducts(pageNumber);