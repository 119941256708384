import React, {useState} from 'react';
import styled from 'styled-components';
import Heading from '../atoms/Heading';
import Strong from '../atoms/Strong';
import Ranking from '../molecules/Ranking';
import arrowIcon from '../../assets/icons/arrow-nav.svg';
import uuid from 'react-uuid';

const RankingWrapper = styled.div`
    border: 1px solid ${({theme}) => theme.color_2};
    border-radius: 10px;
    overflow: hidden;
    box-shadow: ${({theme}) => theme.shadow};
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
`;
const StyledHeading = styled(Heading)`
    padding: 10px;
    background: ${({theme}) => theme.color_3};
    font-size: 18px;
    position: relative;
    margin: 0;
    &:before {
        content: '';
        position: absolute;
        right: 10px;
        top: calc(50% - 13px);
        width: 26px;
        height: 26px;
        background: url(${arrowIcon});
        transition-duration: 0.4s;
        transform: ${({isActive}) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
        border: 1px solid white;
        border-radius: 50%;
    }
`;
const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: ${({isActive}) => (isActive ? 'block' : 'none')};
`;

const RankingList = ({value, name, index}) => {
    const [isActive, setIsActive] = useState(false);
    const handleVisible = () => {
        setIsActive(!isActive);
    }
    return (
        <RankingWrapper>
            <StyledHeading small white onClick={() => handleVisible()} isActive={index === 0 ? !isActive : isActive}>Miesiąc: <Strong>{name}</Strong></StyledHeading>
            <Wrapper isActive={index === 0 ? !isActive : isActive}>
                {
                    value.map(({user, totalQuantity, orderQuantity, reward, position}) => (
                        <Ranking userID={user.id} created={totalQuantity} realizated={orderQuantity} prize={reward} position={position} key={uuid()}></Ranking>
                    ))
                }
            </Wrapper>
        </RankingWrapper>
    )
};

export default RankingList;