import React, {useContext, useState, useEffect} from 'react';
import logo from '../../assets/logo.png';
import styled, {css} from 'styled-components';
import { routes } from '../../routes';
import { NavLink } from 'react-router-dom';
import NavigationLink from '../atoms/NavigationLink';
import InnerNavigation from './InnerNavigation';
import uuid from 'react-uuid';
import { PageContext } from '../../context/PageContext';
import Button from '../atoms/Button';

const Wrapper = styled.div`
    width: 100%;
    background: ${({theme}) => theme.color_3};
    position: fixed;
    z-index: 999999;
    transition-duration: 0.1s;
    top: 0;
    left: 0;
    padding: 0 5px;
    ${({active}) =>
        active && css`
            box-shadow: ${({theme}) => theme.shadow};
        `
    }
`;
const Navigation = styled.nav`
    ${({theme}) => theme.container};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    border-bottom: 1px solid ${({theme}) => theme.color_4};
    @media (max-width: 980px) {
        padding: 20px 10px;
    }
    ${({active}) =>
        active && css`
        border: none;
        transition-duration: 0.2s;
        padding: 0;
    `
    }
`;

const Logo = styled.img`
    width: 180px;
    position: relative;
    transition-duration: 0.5s;
    ${({mobilemenu}) =>
        mobilemenu && css`
            margin-top: 140px;
        `
    }
    ${({active}) =>
        active && css`
            width: 150px;
        `
    }
    @media(max-width: ${({theme}) => theme.tablet}) {
            height: 25px;
            width: auto;
        }
`;
const Nav = styled.div`
    padding: 0;
    margin: 0;
    @media (max-width: 980px) {
        transition-duration: 0.4s;
        transform: ${({isActive}) => (isActive ? 'translateX(0)' : 'translateX(100%)')};
        background: ${({theme}) => theme.color_3};
        position: fixed;
        width: 100%;
        min-height: 100vh;
        z-index: 99999;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
const ListWrapper = styled.ul`
    display: flex;
    @media (max-width: 980px) {
        flex-direction: column;
        margin: 20px 0 0;
        padding: 0;
        width: 100%;
    }
`;
const ListItem = styled.li`
    list-style: none;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 980px) {
        background: ${({theme}) => theme.color_6};
        font-size: 18px;
        padding: 5px;
        margin: 2px 10px;
        padding: 5px;
        text-align: center;
    }
`;
const NavigationButton = styled.div`
    display: none;
    @media (max-width: 980px) {
        display: block;
        width: 40px;
        height: 30px;
        z-index: 99999;
        border-top: 2px solid white;
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            right: 0;
            height: 2px;
            background: white;
            transition-duration: 0.3s;
        }
        &:before {
            width: 100%;
            top: calc(50% - 2px);
        }
        &:after {
            width: 100%;
            bottom: 0;
        }
    ${({isActive}) =>
        isActive && css`
            border-top: 0;
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                top: calc(50% - 2px);
                transform: rotate(-45deg);
            }
        `}
    }
`;

const MainNavigation = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isActiveNavigation, setIsActiveNavigation] = useState(false);
    const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
    const {resetProductsFilters} = useContext(PageContext);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 20) {
                setIsActiveNavigation(true);
            } else {
                setIsActiveNavigation(false);
            }
        });
    })

    const isVisibleMenu = () => {
        setIsVisible(!isVisible);
    }
    const changeActiveMobileMenu = () => {
        setIsActiveMobileMenu(!isActiveMobileMenu);
    }
        return (
            <Wrapper active={isActiveNavigation}>
                <Navigation active={isActiveNavigation}>
                    <a href={routes.home}>
                    <Logo src={logo} active={isActiveNavigation}/>
                    </a>
                        <Nav isActive={isActiveMobileMenu}>
                            {
                                isActiveMobileMenu && (
                                    <a href={routes.home}>
                                    <Logo src={logo} mobilemenu="true"></Logo>
                                    </a>
                                )
                            }
                            <ListWrapper>
                                <ListItem key={uuid()}>
                                    <NavigationLink dropdown onClick={isVisibleMenu} activeItem={isVisible}>Informacje</NavigationLink>
                                    <InnerNavigation isVisible={isVisible} isVisibleMenu={isVisibleMenu} closeMobileMenu={changeActiveMobileMenu} isActiveMobileMenu={isActiveMobileMenu}/>
                                </ListItem>
                                <ListItem key={uuid()}>
                                    <NavigationLink as={NavLink} to={routes.news} onClick={() => {isActiveMobileMenu && changeActiveMobileMenu()}}>Aktualności</NavigationLink>
                                </ListItem>
                                <ListItem key={uuid()}>
                                    <NavigationLink as={NavLink} to={routes.ranking} onClick={() => {isActiveMobileMenu && changeActiveMobileMenu()}}>Ranking</NavigationLink>
                                </ListItem>
                                <ListItem key={uuid()}>
                                    <NavigationLink as={NavLink} to={routes.state} onClick={() => {isActiveMobileMenu && changeActiveMobileMenu()}}>Zmiany stanów</NavigationLink>
                                </ListItem>
                                <ListItem key={uuid()}>
                                    <NavigationLink as={NavLink} to={routes.products} onClick={() => {resetProductsFilters(); isActiveMobileMenu && changeActiveMobileMenu()}}>Produkty</NavigationLink>
                                </ListItem>
                                <ListItem key={uuid()}>
                                    <Button href={routes.salesPanel} small="true" primary="true" target="_blank">Panel sprzedawcy</Button>
                                </ListItem>
                            </ListWrapper>
                        </Nav>
                        <NavigationButton onClick={changeActiveMobileMenu} isActive={isActiveMobileMenu}/>
                    </Navigation>
            </Wrapper>
        )
}


export default MainNavigation;
