import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import Heading from '../atoms/Heading';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import { PageContext } from '../../context/PageContext';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';

const Wrapper = styled.div`
    width: 40%;
    @media(max-width: ${({theme}) => theme.phone}) {
        width: 100%;
        flex-direction: column;
        text-align: center;
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
`;
const StyledButton = styled(Button)`
    border-radius: 0 50px 50px 0;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
`;
const StyledInput = styled(Input)`
    height: 100%;
`;

const SearchBar = () => {
    const {filterPorductsByName, setSearchValue} = useContext(PageContext);
    const [filterValue, setFilterValue] = useState();

    const filterProducts = () => {
        setSearchValue(filterValue);
        filterPorductsByName();
        setFilterValue("");
    }

    return (
        <Wrapper>
            <Heading small white normal>Szukaj produktu</Heading>
            <InnerWrapper>
                <StyledInput search
                    value={filterValue}
                    placeholder="Szukaj"
                    onChange={(e) => {
                                    setFilterValue(e.target.value)
                                    }}/>
                <StyledButton small="true" onClick={() => filterProducts()} as={NavLink} to={routes.products}>Szukaj</StyledButton>
            </InnerWrapper>
        </Wrapper>
    )
}

export default SearchBar;
