import React from 'react';
import styled from 'styled-components';
import MainNavigation from '../molecules/MainNavigation';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';

const Wrapper = styled.header`
    background: ${({theme}) => theme.color_3};
    width: 100%;
    padding-top: 80px;
`;
const Description = styled.div`
    ${({theme}) => theme.container};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    position: relative;
    z-index: 1;
    text-align: center;
`;

const Header = ({title, content}) => {
    return (
        <Wrapper>
            <MainNavigation/>
            <Description>
                        <Heading white as={'h1'} center big>{title}</Heading>
                        {
                            content && (
                                <Paragraph size={'18px'} white>{content}</Paragraph>
                            )
                        }
            </Description>
        </Wrapper>
    )
};

export default Header;