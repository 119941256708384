import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';

const Wrapper = styled.div`
    background: ${({theme}) => theme.color_6};
    text-align: center;
    padding: 25px 0;
`;

const Copyright = () => (
    <Wrapper>
        <Paragraph white size={'14px'} smallmobile>
            2020 © Pichers / Wszelkie prawa zastrzeżone
        </Paragraph>
    </Wrapper>
);

export default Copyright;