import React, {useContext} from 'react';
import styled from 'styled-components';
import Heading from '../components/atoms/Heading';
import uuid from 'react-uuid';
import ProductsTemplates from '../templates/ProductsTemplates';
import { PageContext } from '../context/PageContext';
import Product from '../components/molecules/Product';
import Pagination from '../components/molecules/Pagination';

const Content = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    @media(max-width: ${({theme}) => theme.tablet}) {
       grid-template-columns: repeat(2, 1fr);
       grid-gap: 20px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;

const ProductPage = () => {
    const { isLoadProducts, currentPageProducts, postsPerPageProducts, setCurrentPageProducts, filterProducts} = useContext(PageContext);
    const indexOfLastPost = currentPageProducts * postsPerPageProducts;
    const indexOfFirsPost = indexOfLastPost - postsPerPageProducts;


    let currentPosts;
    if (filterProducts.length) {
        currentPosts = filterProducts.slice(indexOfFirsPost, indexOfLastPost);
    }
    const paginate = (pageNumber) => setCurrentPageProducts(pageNumber);
    return isLoadProducts ? (
        <ProductsTemplates>
            <Content>
            {
              currentPosts ? (
                    <>
                        {
                            currentPosts.map(({id, name, images, profit, priceGross, sku, state}) => (
                                <Product
                                    id={id}
                                    key={uuid()}
                                    name={name}
                                    image={images[0]}
                                    profit={profit}
                                    price={priceGross}
                                    productsKey={sku}
                                    state={state}
                                />
                            ))
                        }
                        </> ) : (
                            <Heading small>Brak produktów</Heading>
                        )
            }
            </Content>
            {
                filterProducts.length > postsPerPageProducts && (
                    <Pagination productsPerPage={postsPerPageProducts} totalProducts={filterProducts.length} paginate={paginate} activeProducts={currentPageProducts}/>
                )
            }
        </ProductsTemplates>
    ) : (
        <ProductsTemplates>
            <p>loading...</p>
        </ProductsTemplates>
    )
}


export default ProductPage;