export const theme = {
    color_1: 'rgb(11, 105, 237)',
    color_1_hover: 'rgb(11, 90, 250)',
    color_3: 'rgb(12, 29, 44)',
    color_2: 'rgb(226, 241, 249)',
    color_4: 'rgb(30, 45, 65)',
    color_5: 'rgb(24, 35, 51)',
    color_6: 'rgb(22, 32, 46)',
    color_11: '#3cbc10',
    color_11_hover: 'rgb(59, 170, 36)',
    danger_color: '#e43341',
    grey_color: '#dee3e9',
    colorrrr: '#46647c',
    shadow: '0px 2px 10px 0px RGBA(57,97,120,0.1)',
    container: {
        width: '100%',
        maxWidth: '1160px',
        margin: '0 auto',
    },
    tablet: '992px',
    phone: '768px'
}