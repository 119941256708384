import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Strong from '../components/atoms/Strong';
import ProductImages from '../components/molecules/ProductImages';
import uuid from 'react-uuid';
import ButtonIcon from '../components/atoms/ButtonIcon';
import downloadIcon from '../assets/icons/download.svg';
import MainNavigation from '../components/molecules/MainNavigation';
import Loading from '../components/molecules/Loading';
import SpecialTemplates from '../templates/SpecialTemplates';
import axios from 'axios';
import { api } from '../api';

const Container = styled.div`
`
const Wrapper = styled.div`
    ${({theme}) => theme.container};
    padding: 150px 10px 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(max-width: ${({theme}) => theme.tablet}) {
        flex-direction: column;
        padding: 100px 10px 25px;
}
`;
const Content = styled.div`
    width: 62%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${({theme}) => theme.color_2};
    padding: 25px 50px;
    border-radius: 10px;
    justify-content: space-between;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
        padding: 20px;
}
@media(max-width: ${({theme}) => theme.phone}) {

}
`;
const Sidebar = styled.div`
    width: 35%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
    }
    @media(max-width: ${({theme}) => theme.phone}) {

    }
`;
const StyledHeading = styled(Heading)`
    border-bottom: 1px solid ${({theme}) => theme.color_2};
    padding: 5px 0;
    font-size: 20px;
    position: relative;
`;
const Description = styled.div`
    margin-top: 30px;
    width: 100%;
    padding: 0 10px;
`;
const Price = styled.p`
    font-size: 25px;
    font-weight: 700;
    border-radius: 5px;
    margin-bottom: 0;
`;
const Key = styled.span`
    display: block;
    background: ${({theme}) => theme.color_4};
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
`;
const Links = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ProductContent = styled.div`
`;

const DetailProductTemplates = ({id, name, profit, description, images, price, sku, state}) => {
    const [imagesList, setImagesList] = useState([]);
    const [isLoadImagesList, setIsLoadImagesList] = useState(false);
    const [imagesToDownload, setImagesToDownload] = useState([]);
        useEffect(() => {
            axios.get(`${api.apiUrl}/product/${id}/images`,{
                headers: {
                    appToken: api.appToken,
                },
                params: {
                    title: '',
                }
            })
            .then(({data}) => {
                setImagesList([...data.data]);
                setIsLoadImagesList(true);
            })
            .catch(err => {
                console.log(err);
            });
        },[id]);
        
    return <>
        <Container>
            <MainNavigation/>
            <Wrapper>
                <Sidebar>
                    <ProductImages images={images}/>
                </Sidebar>
                    <Content>
                            <Key>Kod produktu: <Strong>{sku}</Strong></Key>
                            <Heading  margin={'10px 0 20px'} small> {name}</Heading>
                            <Price>Cena: {price} zł</Price>
                            <Paragraph>Twój zarobek <Strong>{profit}zł</Strong> (brutto)</Paragraph>
                            <Paragraph margin={'10px 0'}>Stan magazynowy:
                            {state === 'non_available' && (
                                    <Strong> Niedostępny</Strong>
                            )}
                            {state === 'low' && (
                                    <Strong> Bliski wyczerpania</Strong>
                            )}
                            {state === 'normal' && (
                                    <Strong> Dostępny</Strong>
                            )}
                            </Paragraph>
                        {/*<Links>*/}
                        {/*<Heading small margin={'50px 0 0'}>Zdjęcia do pobrania:</Heading>*/}
                        {/*{   isLoadImagesList ? (*/}
                        {/*        imagesList.map((image, index) => (*/}
                        {/*            <ButtonIcon href={'data:image/jpeg;base64,' + image.base64} download={image.name} key={uuid()} small primary margin={'5px 5px 5px 0'} icon={downloadIcon}>zdjęcie {index + 1}</ButtonIcon>*/}
                        {/*    ))) : <Paragraph>Wczytywanie...</Paragraph>*/}
                        {/*}*/}
                        {/*</Links>*/}
                    </Content>
                <Description>
                    <StyledHeading>Opis produktu:</StyledHeading>
                    <ProductContent dangerouslySetInnerHTML={{ __html: description}} />
                </Description>
            </Wrapper>
        </Container>
    </>
};

export default DetailProductTemplates;
