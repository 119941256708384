import React from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';
import img from '../../assets/undraw_Landing_page_re_6xev.svg';
import { routes } from '../../routes';

const Wrapper = styled.div`
    ${({theme}) => theme.container};
    border-radius: 10px;
    padding: 100px 10px;
    align-items: center;
    position: relative;
    @media(max-width: ${({theme}) => theme.tablet}) {
        padding: 50px;
        width: calc(100% - 20px);
    }
    @media(max-width: ${({theme}) => theme.phone}) {
    }
`;
const Image = styled.img`
    width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: auto;
        height: 200px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        display: none;
    }
`;
const Description = styled.div`
    max-width: 60%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        max-width: 100%;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;
const StyledParagraph = styled(Paragraph)`
    line-height: 35px;
    font-size: 20px;
    margin: 25px 0;
`;

const ShopBanner = () => (
    <Wrapper>
        <Description>
            <Heading>Stwórz swój własny sklep</Heading>
            <StyledParagraph>Każdy użytkownik, który nawiązał z nami owocną współpracę ma możliwość stworzenia swojego własnego sklepu internetowego. Sklep jest powiązany z Twoim kontem, a wszystkie zamówienia realizowane są automatycznie, co pozwala skutecznie zwiększyć Twoją sprzedaż.</StyledParagraph>
            <Button primary="true" href={routes.salesPanel} target="_blank">Czytaj więcej</Button>
        </Description>
        <Image src={img}/>
    </Wrapper>
);

export default ShopBanner;