import React, {useContext} from 'react';
import SpecialTemplates from '../templates/SpecialTemplates';
import Loading from '../components/molecules/Loading';
import Heading from '../components/atoms/Heading';
import Date from '../components/atoms/Date';
import { Redirect } from 'react-router-dom';
import { PageContext } from '../context/PageContext';


const DetailNewsPage = (props) => {
    const {news, isLoadNews} = useContext(PageContext);

    let newsToRender;
    if (isLoadNews) {
        newsToRender = news.filter(content => content.friendlyUrl === props.match.params.id);
    }

        return isLoadNews ? (
            newsToRender.length ? (
                newsToRender.map(({title, content, datePublished}) => (
                    <SpecialTemplates title="Aktualności" content={title} type="detail">
                        <Date>{datePublished}</Date>
                        <Heading>{title}</Heading>
                        <div dangerouslySetInnerHTML={{ __html: content}} />
                    </SpecialTemplates>
            ))) :
            (
                <Redirect to="/page-not-found"/>
            )
        ) : (
            <SpecialTemplates>
                <Loading/>
            </SpecialTemplates>
        )
}
export default DetailNewsPage;

