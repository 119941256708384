import React, {useState, useEffect} from 'react';
import SpecialTemplates from '../templates/SpecialTemplates';
import axios from 'axios';
import { api } from '../api';
import RankingList from '../components/organisms/RankingList';
import uuid from 'react-uuid';
import Loading from '../components/molecules/Loading';


const RankinPages = () => {
    const [rankings, setRankings] = useState({});
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
            axios.get(`${api.apiUrl}/user/ranking`, {
                headers: {
                    appToken: api.appToken,
                }
            })
            .then(({data}) => {
                setRankings({...data.data});
                setIsLoad(true);
            })
            .catch(err => (console.log(err)));
    },[])

    return isLoad ? (
        <SpecialTemplates title="Ranking" type="ranking">
        {
            Object.keys(rankings).map((key, index) => (
                <RankingList
                    index={index}
                    name={key}
                    value={rankings[key]}
                    key={uuid()}
                />
            ))
        }
        </SpecialTemplates>
    ) : (
        <SpecialTemplates title="Ranking">
            <Loading/>
        </SpecialTemplates>
    )
}

export default RankinPages;