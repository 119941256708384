import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {routes} from '../../routes';
import styled from 'styled-components';
import Heading from '../atoms/Heading';
import Date from '../atoms/Date';
import ButtonIcon from '../atoms/ButtonIcon';
import arrowIcon from '../../assets/icons/right-arrow-dark.svg';

const Wrapper = styled.div`
    padding: 25px 25px 15px;
    background: white;
    margin: 0 5px;
    border-radius: 10px;
    box-shadow: ${({theme}) => theme.shadow};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
`;
const Wrap = styled.div`
`;
const StyledButtonIcon = styled(ButtonIcon)`
    background: none;
    font-weight: normal;
    padding: 10px 60px 10px 0;
    color: black;
    &:hover {
        background: none;
        text-decoration: underline;
    }
`;
const Post = ({name, date, id}) => (
    <Wrapper>
        <Wrap>
            <Date>{date}</Date>
            <Heading size={'20px'}>{name}</Heading>
        </Wrap>
        <StyledButtonIcon as={NavLink} to={routes.news + '/' + id} icon={arrowIcon}>czytaj więcej</StyledButtonIcon>
    </Wrapper>
);

Post.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
};
Post.defaultProps = {
    name: 'brak',
    content: 'brak',
    date: 'brak',
    id: 'brak',
}

export default Post;