import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';
import Strong from '../atoms/Strong';
import { routes } from '../../routes';
import uuid from 'react-uuid';
import availableIcon from '../../assets/icons/available.svg';
import lackIcon from '../../assets/icons/lack.svg';
import endingIcon from '../../assets/icons/ending.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    background: white;
    position: relative;
    box-shadow: ${({theme}) => theme.shadow};
    width: 100%;
    text-decoration: none;
    transition-duration: 0.3s;
    &:hover {
        box-shadow: 0 2px 30px 0 RGBA(57,97,120,.22);
    }
`;
const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: 220px;
    border-bottom: 1px solid ${({theme}) => theme.color_2};
`;
const Image = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: 50% 60%;
    height: 100%;
`
const InnerWrapper = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
const Key = styled.span`
    position: absolute;
    top: 10px;
    left: 10px;
    background: ${({theme}) => theme.color_3};
    color: white;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
`;
const StyledHeading = styled(Heading)`
    &:hover {
        color: ${({theme}) => theme.color_1};
    }
`;
const StateIcon = styled.span`
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: ${({activeColor, theme}) => (activeColor ? theme[activeColor] : theme.color_3)};
    background-image: url(${({icon}) => icon});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: calc(100% - 2px);
    border-radius: 50px;
    top: -10px;
    right: -10px;
`;

const Product = ({id, name, image, profit, price, productsKey, state}) => (
    <Wrapper key={uuid()} as={NavLink} to={routes.products + '/' + id}>
        <ImageWrapper>
            <Image src={image}/>
        </ImageWrapper>
        <InnerWrapper>
        <StyledHeading size={'16px'} center>{name}</StyledHeading>
        <Paragraph size={'18px'} bold margin={'10px 0 0'}>Cena: {price} zł</Paragraph>
        <Paragraph size={'14px;'} >Twój zarobek: <Strong>{profit} zł</Strong> (brutto)</Paragraph>
        <Key>Kod produktu: <Strong>{productsKey}</Strong></Key>
        {/* <Button small={'small'} margin={'10px 0 0'} primary={'primary'} as={NavLink} to={routes.products + '/' + id}>wyświetl szczegóły</Button> */}
        </InnerWrapper>
        {state === 'non_available' && (
            <StateIcon activeColor={'danger_color'} icon={lackIcon}/>
        )}
        {state === 'low' && (
            <StateIcon activeColor={'color_4'} icon={endingIcon}/>
        )}
        {state === 'normal' && (
            <StateIcon activeColor={'color_11'} icon={availableIcon}/>
        )}
    </Wrapper>
);


export default Product;