import React from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ImageCss = {
    objectFit: "cover"
}
const ImageItem = styled.div`
    border: 5px solid #f9f9fb;
    border-radius: 5px;
    width: calc(100% / 3);
    background: white;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 120px;
    &:first-child {
        width: 100%;
        height: 300px;
    }
`;
const ProductImages = ({images}) => (
        <SimpleReactLightbox>
        <SRLWrapper>
            <Wrapper>
        {
            images[0] && (
                <ImageItem key={uuid()}>
                    <a href={images[0]}>
                        <LazyLoadImage src={images[0]} effect="blur" width="100%" style={ImageCss} height="300px" />
                    </a>
                </ImageItem>
            )
        }
        </Wrapper>
        </SRLWrapper>
        </SimpleReactLightbox>
);
export default ProductImages;
