import React from 'react';
import styled from 'styled-components';
import Paragraph from '../atoms/Paragraph';
import Heading from '../atoms/Heading';
import FooterNavigation from '../molecules/FooterNavigation';
import logo from '../../assets/logo.png';
import FooterTopBanner from '../molecules/FooterTopBanner';
import Copyright from '../molecules/Copyright';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';
import NavigationLink from '../atoms/NavigationLink';

const Wrapper = styled.div`

`;
const InnerWrapper = styled.div`
    background: ${({theme}) => theme.color_5};
`;
const Container = styled.div`
    ${({theme}) => theme.container};
    padding: 50px 0px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 50px;
    justify-content: space-between;
    @media(max-width: ${({theme}) => theme.tablet}) {
        padding: 50px 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        display: flex;
        flex-direction: column;
    }
`;
const About = styled.div`
`;
const Logo = styled.img`
    width: 150px;
    margin-bottom: 15px;
`;
const Contact = styled.div`

`;
const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    @media(max-width: ${({theme}) => theme.phone}) {
        display: none;
    }
`;

const Footer = () => (
    <Wrapper>
    <FooterTopBanner/>
    <InnerWrapper>
        <Container>
        <About>
            <a href={routes.home}>
                <Logo src={logo}/>
            </a>
            <Paragraph white size={'14px'}>Jesteśmy firmą z kilkuletnim doświadczeniem zajmującą się sprzedażą internetową. Dołączając do naszego zespołu otrzymujesz możliwość szybkiego zarobku jako pośrednik sprzedający nasza produkty.</Paragraph>
        </About>
        <Contact>
            <Heading size={'18px'} white>
                Kontakt
            </Heading>
            <Paragraph white size={'14px'}>PICHERS KONRAD PISZCZEK</Paragraph>
            <Paragraph white size={'14px'}>NIP: 737 22 06 291</Paragraph>
            <Paragraph white size={'14px'}> ul. Bulwary 13,</Paragraph>
            <Paragraph white size={'14px'}>34-600 Limanowa</Paragraph>
        </Contact>
        <Navigation>
            <Heading size={'18px'} white>
                Informacje
            </Heading>
            <FooterNavigation/>
        </Navigation>
        <Navigation>
            <Heading size={'18px'} white>
                Pozostałe
            </Heading>
            <NavigationLink footer="true" as={NavLink} to={routes.news}>Aktualności</NavigationLink>
            <NavigationLink footer="true" as={NavLink} to={routes.ranking}>Ranking</NavigationLink>
            <NavigationLink footer="true" as={NavLink} to={routes.products}>Produkty</NavigationLink>
            <NavigationLink footer="true" as={NavLink} to={routes.state}>Statusy</NavigationLink>
        </Navigation>
        </Container>
    </InnerWrapper>
    <Copyright/>
    </Wrapper>
);

export default Footer;