import React from 'react';
import styled from 'styled-components';
import Header from '../components/organisms/Header';
import Banner from '../components/organisms/Banner';
import RankingBar from '../components/organisms/RankingBar';
import StateBanner from '../components/organisms/StateBanner';

const Wrapper = styled.div`
    ${({theme}) => theme.container};
    width: 100%;
    padding: 50px 10px;
    display: flex;
    justify-content: space-between;
    @media(max-width: ${({theme}) => theme.tablet}) {
        flex-direction: column;
        padding: 25px 20px;
    }
`;

const Content = styled.div`
    width: 70%;
    padding-right: 50px;
    overflow: hidden;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
        padding: 0 0 50px 0;
    }
`;
const RightBar = styled.div`
    width: 28%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
    }
`;
const PageTemplates = ({title, children}) => (
    <>
    <Header title={title}/>
    <Wrapper>
        <Content>
            {children}
        </Content>
        <RightBar>
            <RankingBar/>
            <StateBanner/>
        </RightBar>
    </Wrapper>
    <Banner/>
</>
);

export default PageTemplates;