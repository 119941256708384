import styled, {css} from 'styled-components';

const Heading = styled.h2`
    color: ${({white, theme}) => (white ? theme.color_2 : theme.color_4)};
    font-size: ${({size}) => size || '30px'};
    margin: ${({margin}) => margin || '0 0 10px'};
    width: 100%;
    text-align: ${({center}) => (center ? 'center' : 'inherit')};
    font-weight: ${({normal}) => (normal ? '400' : '700')};
    padding: ${({padding}) => padding || '0'};
    ${({big}) =>
        big && css`
        font-size: 36px;
        @media(max-width: ${({theme}) => theme.phone}) {
            font-size: 28px;
    }
    `}
    ${({small}) =>
        small && css`
            font-size: 22px;
            @media(max-width: ${({theme}) => theme.phone}) {
                font-size: 20px;
        }
    `}
    @media(max-width: ${({theme}) => theme.phone}) {
    }
`;

export default Heading;
