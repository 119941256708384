import React, {useContext, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';
import { PageContext } from '../../context/PageContext';
import axios from 'axios';
import { api } from '../../api';
import uuid from 'react-uuid';
import arrowIcon from '../../assets/icons/arrow-nav-black.svg';

const CategorysList = styled.ul`
    display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 280px;
    margin: 0;
    padding: 0 0 50px;
    width: 100%;
    @media(max-width: ${({theme}) => theme.phone}) {
        flex-wrap: nowrap;
        height: auto;
        padding: 10px;
        margin: 0;
    }
    ${({categoryinmenu}) =>
        categoryinmenu && css`
            height: 180px;
        `
    }
`;
const CategorysItem = styled.li`
    list-style: none;
    display: block;
    padding: 5px 5px 5px 20px;
    position: relative;
    color: black;
    text-decoration: none;
    transition-duration: 0.3s;
    font-size: 14px;
    border-radius: 5px;
    &:before {
        content: '';
        width: 18px;
        height: 18px;
        background-image: url(${arrowIcon});
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: 50%;
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
    }
    &:hover {
        transform: translateX(10px);
    }
    ${({categoryinmenu}) =>
        categoryinmenu && css`
            font-weight: 700;
            width: 50%;
            font-size: 12px;
            padding: 2px 0 2px 20px;
        `
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        width: 100%;
    }
`;
const InnerCategorysItem = styled(CategorysItem)`
    padding-left: 45px;
    &:before {
        left: 20px;
    }
    ${({isMenuCategory}) =>
        isMenuCategory && css`
        `
    }
`;
const Category = ({isVisible, setVisibleBar, isMenuCategory, isVisibleMenu}) => {
    const {category, isLoadCategory, setCategory, setIsLoadCategory} = useContext(PageContext);
    useEffect(() => {
        if (isLoadCategory === false) {
            axios.get(`${api.apiUrl}/category`, {
                headers: { appToken: api.appToken }
            })
            .then(({data}) => {
                setCategory([...data.data]);
                setIsLoadCategory(true);
            })
            .catch(err => console.log(err));
        }
    }, [isLoadCategory])

    return isLoadCategory ? (
        <CategorysList isVisible={isVisible} categoryinmenu={isMenuCategory}>
            {
                category.filter(list => (list.idParent === 0)).map(({id, name}) => (
                        <>
                            <CategorysItem
                                as={NavLink}
                                to={`/kategoria/${id}`}
                                key={uuid()}
                                onClick={() => {
                                    if (isMenuCategory) {
                                        isVisibleMenu();
                                    } else {
                                        setVisibleBar(); 
                                    }
                                }}
                                categoryinmenu={isMenuCategory}
                            >
                                    {name}
                            </CategorysItem>
                        {
                            category.filter(item => (
                                item.idParent === id
                            )).map(item => (
                                <InnerCategorysItem
                                    as={NavLink}
                                    to={`/kategoria/${item.id}`}
                                    key={uuid()}
                                    categoryinmenu={isMenuCategory}
                                    onClick={() => {
                                        if (isMenuCategory) {
                                            isVisibleMenu();
                                        } else {
                                            setVisibleBar(); 
                                        }
                                    }}
                                >
                                    {item.name}
                                </InnerCategorysItem>
                            ))

                        }
                        </>
                ))
            }
        </CategorysList>
    ) : (
        <></>
    )
};
export default Category;