import React, {useContext} from "react";
import styled from 'styled-components';
import Loading from '../components/molecules/Loading';
import { PageContext } from '../context/PageContext';
import HomeTemplate from '../templates/HomeTemplates';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import okIcon from '../assets/icons/ok.svg';
import Strong from '../components/atoms/Strong';

const ContentPage = styled.div`
    line-height: 24px;
    text-align: justify;
    color: ${({theme}) => theme.color_3};
    & p {
        margin: 8px 0;
    }
    & h4 {
        font-size: 22px;
        margin: 20px 0;
    }
    & ul {
        padding: 0;
    }
    & li {
        list-style: none;
        position: relative;
        padding: 5px 0 5px 40px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 12px);
            background-color: ${({theme}) => theme.color_2};
            background-image: url(${okIcon});
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 60%;
            width: 24px;
            height: 24px;
            border-radius: 50px;
        }
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        font-size: 14px;
        & h4 {
            font-size: 15px;
        }
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
`;
const Card = styled.div`
    /* background: ${({theme}) => theme.color_2}; */
    padding: 25px 25px 25px 0;
    width: 50%;
    @media(max-width: ${({theme}) => theme.phone}) {
        width: 100%;
        padding: 20px 0;
    }
`;
const LastCard = styled(Card)`
    width: 100%;
`;
const List = styled.ul`
    padding: 0;
    margin-bottom: 50px;
`;
const ListItem = styled.li`
    list-style: none;
    position: relative;
    padding: 5px 0 5px 40px;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 12px);
        background-color: ${({theme}) => theme.color_2};
        background-image: url(${okIcon});
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 60%;
        width: 24px;
        height: 24px;
        border-radius: 50px;

    }
`;
const Number = styled(Heading)`
    color: ${({theme}) => theme.color_2};
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 0;
`;

const HomePage = () => {
    const {pages, isLoadPages, theLatestProducts} = useContext(PageContext);

    return isLoadPages ? (
        pages.filter(item => item.position === 1).map(({content}) => (
            <HomeTemplate newProducts={theLatestProducts}>
                <Heading small margin={'0 0 20px'}> Jak wygląda nasza współpraca?</Heading>
                <Paragraph size="14px" smallmobile="true">Czym więcej pracujesz, poświęcasz temu więcej czasu, angażujesz się tym <Strong>WIĘCEJ ZARABIASZ.</Strong> Wiem sam po sobie. Tylko <Strong>pięć kroków</Strong> dzieli Cię od otrzymania wynagrodzenia.</Paragraph>
                <InnerWrapper>
                    <Card>
                        <Number>01.</Number>
                        <Strong>Wystawiasz produkty</Strong>
                        <Paragraph margin={'10px 0 0'} size="14px" smallmobile="true">Wystawiasz dowolne nasze produkty na Facebook Market Place i za każdy sprzedany przedmiot wypłacamy Ci kwotę przypisaną do produktu. Do każdego produktu&nbsp;udostępniamy nasz opis oraz zdjęcia.</Paragraph>
                    </Card>
                    <Card>
                        <Number>02.</Number>
                        <Strong>Obsługujesz zamówienie</Strong>
                        <Paragraph margin={'10px 0 0'} size="14px" smallmobile="true">Klienci sami piszą, a Ty nie musisz nic więcej robić jak tylko ich obsługa przez Facebook Messenger i późniejsze wprowadzenie zamówienia.</Paragraph>
                    </Card>
                    <Card>
                        <Number>03.</Number>
                        <Strong>Wprowadzasz zamówienie do naszego systemu</Strong>
                        <Paragraph margin={'10px 0 0'} size="14px" smallmobile="true">Gdy jakiś klient zdecyduje się na zakup, wprowadzasz nowe zamówienie do systemu wraz z potrzebnymi danymi do wysyłki klienta - adres, numer telefonu oraz produkty które zamówił.</Paragraph>
                    </Card>
                    <Card>
                        <Number>04.</Number>
                        <Strong>Realizujemy Twoje zamówienie</Strong>
                        <Paragraph margin={'10px 0 0'} size="14px" smallmobile="true">Przesyłki do klientów wysyłamy tylko za pobraniem (płatne przy odbiorze), aby klient miał możliwość sprawdzenia zawartości przy kurierze, a do każdego zamówienia dorzucamy paragon fiskalny, bądź na życzenie klienta fakturę VAT.</Paragraph>
                    </Card>
                    <LastCard>
                        <Number>05.</Number>
                        <Strong>Wypłacamy Ci pieniądze</Strong>
                        <Paragraph margin={'10px 0 0'} size="14px" smallmobile="true">Gdy zamówienie zostanę odebrane przez klienta Twoje saldo na koncie zostaje powiększone o wartość prowizji z zamówienia. Tak zarobione pieniądze możesz wypłacać w dowolnym momencie.&nbsp;Możesz sprzedać produkt drożej wtedy doliczymy różnice do Twojej prowizji. Tzn. produkt, który kosztuje u nas 25zł, a prowizja wynosi 4zł, możesz go sprzedać za np. 35zł wtedy Twoja prowizja wynosi 4zł + 10zł (różnica cen) = 14zł.</Paragraph>
                    </LastCard>
                </InnerWrapper>
                <Heading small margin={'20px 0'}> Czego potrzebujesz?</Heading>
                <List>
                    <ListItem>Komputer lub telefon</ListItem>
                    <ListItem bold>Konto na facebooku</ListItem>
                    <ListItem bold>Trochę czasu i zaangażowania.</ListItem>
                    <ListItem bold>Dowiedzieć się paru ważnych kwestii, które znajdziesz i zakładce Jak zacząć?</ListItem>
                </List>
                <ContentPage dangerouslySetInnerHTML={{ __html: content}} />
            </HomeTemplate>
        ))
    ) : (
        <HomeTemplate>
           <Loading/>
        </HomeTemplate>
    )
};

export default HomePage;