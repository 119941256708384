import React from 'react';
import uuid from "react-uuid";
import styled from 'styled-components';
import Heading from "../components/atoms/Heading";
import Product from "../components/molecules/Product";
import MainHeader from '../components/organisms/MainHeader';
import PostsBar from '../components/organisms/PostsBar';
import RankingBar from '../components/organisms/RankingBar';
import StateBanner from '../components/organisms/StateBanner';
import Banner from '../components/organisms/Banner';
import ShopBanner from '../components/organisms/ShopBanner';

const Wrapper = styled.div`
    ${({theme}) => theme.container};
    width: 100%;
    padding: 50px 10px;
    display: flex;
    justify-content: space-between;
    @media(max-width: ${({theme}) => theme.tablet}) {
        flex-direction: column;
        padding: 25px 20px;
    }
`;

const Content = styled.div`
    width: 70%;
    padding-right: 50px;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
        padding: 0;
    }
`;
const RightBar = styled.div`
    width: 28%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        width: 100%;
    }
`;
const Container = styled.div`
    background: ${({theme}) => theme.color_2};
`;

const StyledHeading = styled(Heading)`
	margin: 50px 0;
`;

const ProductContent = styled.div`
	padding: 50px 0;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    @media(max-width: ${({theme}) => theme.tablet}) {
       grid-template-columns: repeat(2, 1fr);
       grid-gap: 20px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;

const HomeTemplate = ({children, newProducts}) => (
<>
    <MainHeader/>
    <Wrapper>
        <Content>
            {children}
        </Content>
        <RightBar>
            <RankingBar/>
            <StateBanner/>
        </RightBar>
    </Wrapper>
    <Container>
        <ShopBanner/>
	</Container>
        <Banner/>
		<StyledHeading center>Nowości!</StyledHeading>
		<ProductContent>
			{
				newProducts?.length ? (
                    newProducts.map(({id, name, images, profit, priceGross, sku, state}) => (
                        <Product
                            id={id}
                            key={uuid()}
                            name={name}
                            image={images[0]}
                            profit={profit}
                            price={priceGross}
                            productsKey={sku}
                            state={state}
                        />
                    ))
				) : <></>
			}
		</ProductContent>
	<Container>
        <PostsBar/>
    </Container>
</>
)

export default HomeTemplate;
