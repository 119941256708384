import React from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import Heading from '../atoms/Heading';
import Paragraph from '../atoms/Paragraph';
import { routes } from '../../routes';
import img from '../../assets/undraw_working_from_anywhere_ub67.svg';

const Wrapper = styled.section`
    background: ${({theme}) => theme.color_3};
`;
const Description = styled.section`
    ${({theme}) => theme.container};
    padding: 100px 0;
    text-align: center;
    position: relative;
    z-index: 999;
    @media(max-width: ${({theme}) => theme.tablet}) {

    }
    @media(max-width: ${({theme}) => theme.phone}) {
        padding: 100px 10px 150px
    }

`;
const Img = styled.img`
    width: 350px;
    position: absolute;
    bottom: -30px;
    left: 0;
    z-index: 0;
    @media(max-width: ${({theme}) => theme.tablet}) {

    }
    @media(max-width: ${({theme}) => theme.phone}) {
        width: 60%;
    }
`;

const Banner = () => (
    <Wrapper>
        <Description>
            <Heading big white center>Zainteresowany?</Heading>
            <Paragraph white size={'22px'} margin={'0 0 20px'}>Osoby współpracujące z nami zarabiają nawet 4 500 zł miesięcznie</Paragraph>
            <Button href={routes.salesPanel} target="_blank">Dołącz do nas</Button>
            <Img src={img}></Img>
        </Description>
    </Wrapper>
);

export default Banner;