import React from 'react';
import styled from 'styled-components';
import SearchBar from '../molecules/SearchBar';
import Button from '../atoms/Button';
import Heading from '../atoms/Heading';
import { routes } from '../../routes';


const Wrapper = styled.div`
    background: ${({theme}) => theme.color_4};
`;
const InnerWrapper = styled.div`
    @media(max-width: ${({theme}) => theme.phone}) {
        display: none;
    }
`;
const Content = styled.div`
    ${({theme}) => theme.container};
    padding: 25px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: ${({theme}) => theme.phone}) {
        flex-direction: column;
    }
`;

const FooterTopBanner = () => (
    <Wrapper>
        <Content>
            <InnerWrapper>
                <Heading white small normal>Jesteś sprzedawcą?</Heading>
                <Button primary="true" small href={routes.salesPanel} target="_blank">
                    Przejdź do panelu sprzedawcy
                </Button>
            </InnerWrapper>
            <SearchBar/>
        </Content>
    </Wrapper>
);

export default FooterTopBanner;