import React, {useEffect, useContext} from 'react';
import {PageContext} from '../../context/PageContext';
import styled from 'styled-components';
import Heading from '../atoms/Heading';
import Rank from '../molecules/Rank';
import ButtonIcon from '../atoms/ButtonIcon';
import { routes } from '../../routes';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../api';
import uuid from 'react-uuid';
import arrowIcon from '../../assets/icons/right-arrow.svg';


const Wrapper = styled.div`
    overflow: hidden;
    width: 100%;
    @media(max-width: ${({theme}) => theme.tablet}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: #f7f7f7;
        border-radius: 10px;
        padding: 20px;
    }
`;
const RankingWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

`
const StyledHeading = styled(Heading)`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 40%;
        height: 3px;
        background: ${({theme}) => theme.color_2};
        @media(max-width: ${({theme}) => theme.tablet}) {
            display: none;
        }
    }
`;


const RankingBar = () => {
    const {ranking, setRanking, isLoadRanking, setIsLoadRanking} = useContext(PageContext);

    useEffect(() => {
        if (!isLoadRanking) {
            const date = new Date();
            const month = date.getMonth() < 9 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`;
            axios.get(`${api.apiUrl}/user/ranking/${date.getFullYear()}-${month}`, {
                headers: {
                    appToken: api.appToken,
            }})
            .then(({data}) => {
                setRanking([...data.data]);
                setIsLoadRanking(true);
            })
            .catch(err => (console.log(err)));
        }
    }, [isLoadRanking]);

        return isLoadRanking ? (
            <Wrapper>
                <StyledHeading small>Ranking miesiąca</StyledHeading>
                    <RankingWrapper>
                        {
                            ranking.map(({user, totalQuantity, orderQuantity, reward, position}) => (
                                <Rank userID={user.id} created={totalQuantity} realizated={orderQuantity} prize={reward} position={position} key={uuid()}></Rank>
                            ))
                        }
                    </RankingWrapper>
                <ButtonIcon as={NavLink} to={routes.ranking} icon={arrowIcon} small="true" primary="true" margin={'15px 0 0'}>Zobacz więcej</ButtonIcon>
            </Wrapper>
        ) : (
        <Wrapper>
        </Wrapper>
        )
}

export default RankingBar;
