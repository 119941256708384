import React, { useContext } from 'react';
import { routes } from '../../routes'
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import State from '../molecules/State';
import Heading from '../atoms/Heading';
import ButtonIcon from '../atoms/ButtonIcon';
import uuid from 'react-uuid';
import { PageContext } from '../../context/PageContext';
import arrowIcon from '../../assets/icons/right-arrow.svg';


const InnerWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 10px;
    @media(max-width: ${({theme}) => theme.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        padding: 25px 10px;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        padding: 0;
        grid-template-columns: 1fr;
    }
`;
const Wrapper = styled.section`
    padding: 50px 0;
    @media(max-width: ${({theme}) => theme.tablet}) {
        text-align: center;
    }
`;
const StyledHeading = styled(Heading)`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 40%;
        height: 3px;
        background: ${({theme}) => theme.color_2};
        @media(max-width: ${({theme}) => theme.tablet}) {
            display: none;
        }
    }
`;

const StateBanner = () => {
    const {status, isLoadStatus} = useContext(PageContext);

    return isLoadStatus ? (
    <Wrapper>
        <StyledHeading small>Zmiany stanów</StyledHeading>
        <InnerWrapper>
            {
                status.slice(0,4).map(({id, type, product, dateAdded }) => (
                    <State
                        key={uuid()}
                        id={id}
                        idProduct={product.id}
                        name={product.name}
                        status={type}
                        date={dateAdded}
                    />
                ))
            }
        </InnerWrapper>
        <ButtonIcon as={NavLink} to={routes.state} icon={arrowIcon} small margin="20px 0">Zobacz więcej</ButtonIcon>

    </Wrapper>
    ) : (
        <Wrapper>
        </Wrapper>
    )
}

export default StateBanner;
