import React, {useContext} from 'react';
import SpecialTemplates from '../templates/SpecialTemplates';
import Loading from '../components/molecules/Loading';
import styled from 'styled-components';
import Post from '../components/molecules/Post';
import { PageContext } from '../context/PageContext';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @media(max-width: ${({theme}) => theme.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        width: 100%;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;

const NewsPage = () => {
    const {news, isLoadNews} = useContext(PageContext);

    return isLoadNews ? (
        <SpecialTemplates title="Aktualności" type="status">
            <Wrapper>
            {
                news.map(({title, content, friendlyUrl, datePublished, status}) => (
                    <Post
                    key={friendlyUrl}
                    name={title}
                    content={''}
                    date={datePublished}
                    time={''}
                    id={friendlyUrl}
                />
                ))
            }
            </Wrapper>
        </SpecialTemplates>
    ) : (
        <Loading/>
    )
}

export default NewsPage;
