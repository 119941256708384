import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap'); */
    *, *::after, *::before {
        box-sizing: border-box;
    }
   body {
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Nunito', sans-serif; */
   }
   body, h1, h2, h3, h4, p, section, header {
       padding: 0;
   }
`;

export default GlobalStyle;