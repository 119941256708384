import React, { useContext } from 'react';
import styled from 'styled-components';
import Post from '../molecules/Post';
import Heading from '../atoms/Heading';
import { PageContext } from '../../context/PageContext';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';
import ButtonIcon from '../atoms/ButtonIcon';
import arrowIcon from '../../assets/icons/right-arrow.svg'

const Wrapper = styled.div`
    ${({theme}) => theme.container};
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;
const Posts = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    margin: 25px 0;
    grid-gap: 25px;
    @media(max-width: ${({theme}) => theme.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        width: 100%;
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
    }
`;

const PostsBar = () => {
    const {news, isLoadNews} = useContext(PageContext);
    return isLoadNews ? (
    <Wrapper>
        <Heading big center>
            Aktualności
        </Heading>
        <Posts>
            {
                news.slice(0,3).map(({title, friendlyUrl, datePublished}) => (
                    <Post
                    key={friendlyUrl}
                    name={title}
                    date={datePublished}
                    id={friendlyUrl}
                />
                ))
            }
        </Posts>
        <ButtonIcon as={NavLink} to={routes.news} icon={arrowIcon} primary="true">Zobacz pozostałe</ButtonIcon>
    </Wrapper>
    ) : (
        <></>
    )
};

export default PostsBar