import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import Input from '../atoms/Input';
import { PageContext } from '../../context/PageContext';
import arrowIcon from '../../assets/icons/arrow-nav.svg';
import Category from '../molecules/Category';
import removeIcon from '../../assets/icons/remove.svg';
import { withRouter } from 'react-router-dom';
import { routes } from '../../routes';

const Wrapper = styled.header`
    background: ${({theme}) => theme.color_2};
    width: 100%;
`;
const Content = styled.div`
    ${({theme}) => theme.container};
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    @media(max-width: ${({theme}) => theme.tablet}) {
        padding: 0 10px;
    }
`;
const InnerWrapper = styled.div`
    display: grid;
    grid-template-areas: 'input filter select';
    grid-template-columns: 3fr 1fr 1fr;
    grid-column-gap: 10px;
    padding: 15px 0;
    @media(max-width: ${({theme}) => theme.tablet}) {
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-areas: 'input' 'select filter';
    }
    @media(max-width: ${({theme}) => theme.phone}) {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        grid-template-areas: 'input' 'select' 'filter'
    }
`;
const FilterButton = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    grid-area: filter;
    background: ${({theme}) => theme.color_3};
    position: relative;
    color: white;
    box-shadow: ${({theme}) => theme.shadow};
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: calc(50% - 12px);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-image: url(${arrowIcon});
        background-repeat: no-repeat;
        background-position: 50%;
        transition-duration: 0.4s;
        border: 1px solid white;
        transform: ${({isVisible}) => (isVisible ? 'rotate(-180deg)' : 'rotate(0)')}
    }
`;
const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    grid-area: input;
    height: 40px;
`;
const RemoveButton = styled.span`
    display: ${({isActive}) => (isActive ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 12px;
    height: 100%;
    width: 50px;
    border-radius: 50px;
    background: url(${removeIcon});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    transition-duration: 0.3s;
    &:hover {
        transform: rotate(180deg);
    }
`;
const Select = styled.select`
    appearance: none;
    background: none;
    font-weight: bold;
    color: white;
    font-size: 14px;
    box-shadow: 0;
    padding: 10px 25px;
    border: none;
    position: relative;
    width: 100%;
    border-radius: 50px;
    z-index: 9999;
    & option {
        color: black;
    }
`;
const SelectWrapper = styled.div`
    grid-area: select;
    display: flex;
    position: relative;
    background: ${({theme}) => theme.color_1};
    border-radius: 50px;
    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: calc(50% - 12px);
        width: 24px;
        height: 24px;
        background-image: url(${arrowIcon});
        background-repeat: no-repeat;
        background-position: 50%;
        transition-duration: 0.4s;
        transform: ${({isVisible}) => (isVisible ? 'rotate(180deg)' : 'rotate(0)')};
        border-radius: 50px;
        border: 1px solid white;
        z-index: 0;
    }

`;
const StyledInput = styled(Input)`
    height: 40px;
`;
const Categorys = styled.div``;
const SearchPanel = (props) => {
    const {searchValue, setSearchValue, setSearchValueType, searchValueType, filterProductsByType, filterPorductsByName, resetProductsFilters} = useContext(PageContext);
    const [isVisibleFilterRemove, setIsVisibleFilterRemove] = useState(false);
    const [isVisibleCategory, setIsVisibleCategory] = useState(false);

    useEffect(() => {
        if (searchValue.length === 0) {
            setIsVisibleFilterRemove(false);
        } else {
            setIsVisibleFilterRemove(true);
            filterPorductsByName()
        }
    },[searchValue]);

    const setVisibleBar = () => {
        setIsVisibleCategory(!isVisibleCategory);
    }
    const redirectToProductsPage = () => {
        props.history.push(routes.products);
    }
    return (
        <Wrapper>
            <Content>
                <InnerWrapper>
                    <FilterButton bold margin={'10px 0 0'} onClick={setVisibleBar} isVisible={isVisibleCategory}>Wybierz kategorie</FilterButton>
                    <InputWrapper>
                        <StyledInput search
                                onChange={(e) => {
                                    if (e.target.value.length > 0) {
                                        setSearchValue((e.target.value))
                                    } else {
                                        resetProductsFilters();
                                        redirectToProductsPage();
                                    }
                                }}
                                value={searchValue}
                                placeholder="Wpisz nazwę lub kod produktu"
                        />
                        <RemoveButton onClick={() => {resetProductsFilters(); redirectToProductsPage()}} isActive={isVisibleFilterRemove}></RemoveButton>
                    </InputWrapper>
                    <SelectWrapper>
                        <Select value={searchValueType} name="filters" onChange={(e) => {setSearchValueType(e.target.value); filterProductsByType(e.target.value); redirectToProductsPage()}}>
                            <option value="default">Sortuj produkty</option>
                            <option value="normal">Dostępne</option>
                            <option value="non_available">Niedostępne</option>
                            <option value="latest">Data (od najnowszego)</option>
                            <option value="default_2">Data (od najstarszego)</option>
                            <option value="a_z">Nazwa [A-Z]</option>
                            <option value="z_a">Nazwa [Z-A]</option>
                            <option value="price">Cena (rosnąco)</option>
                            <option value="price_2">Cena (malejąco)</option>
                            <option value="profit">Prowizja (rosnąco)</option>
                            <option value="profit_2">Prowizja (malejąco)</option>
                        </Select>
                    </SelectWrapper>
                </InnerWrapper>
                <Categorys>
                <Category isVisible={isVisibleCategory} setVisibleBar={setVisibleBar}/>
                </Categorys>
            </Content>
        </Wrapper>
    )
};

export default withRouter(SearchPanel);
